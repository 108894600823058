

.Header{
    /* border: solid purple 2px; */
    display: flex;
    position: fixed;
    height: 80px;
    width: 100%;
    background-color:transparent; 
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 8%;
    z-index: 10;
    border: var(--debug-border);
}

.Header span:hover path{
    stroke: hsl(195.3,100%,16.9%);
}
.Header span:hover{
    background-color: #e0dfdf;
}


.Header svg{
    stroke: hsl(195.3,100%,16.9%);
    fill: hsl(195.3,100%,16.9%);
    stroke-width: .4px;
}



.Header .header-icon{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border: var(--debug-border);

}

.Header .back-div{
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    z-index: 10;
    cursor: pointer;
    border: var(--debug-border);
    height: 40px;
    background-color: var(--bg-secondary);
    padding: 0 10px 0 10px;
    border-radius: 4px;
}

.Header .arrow-div{
    display: inline-flex;
    border: var(--debug-border);
    width: 18px;
}

.Header h4{
    font-family: 'Roboto Mono', monospace;
    font-weight: bolder;
    border: var(--debug-border);
    margin: 0;
    padding: 0 0 0 4px;
}


