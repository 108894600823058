

.InfoModal{
    width: 96vh !important; 
    max-width:480px;  /* Occupy the 90% of the screen width */
    height: 93vh !important;
    margin: 0;
    /* background-color: red; */
    overflow: hidden;
}
.InfoModal .body{
    padding: 30px 0 0 0px;
}

.InfoModal .p-dialog-header{
    border: var(--debug-border);   
    padding: 20px 20px 15px 20px !important;
}

.InfoModal h2{
    color: #003F56 ;
    font-size:36px ;
    font-family: 'Manrope', sans-serif;
    font-weight: 800; /* Extra bold */
}
.InfoModal h3{
    color: #003F56;
    font-family: 'Manrope', sans-serif;
    font-size:22px;
    font-weight: 800; /* Extra bold */
  }

.InfoModal .p-dialog-content{
    /* border: var(--debug-border);     */
    border-top: #E5E5E5 solid 2px;
    border-bottom:  #E5E5E5 solid 2px;;
}


.InfoModal p{
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color:#212121;
}

.info-footer{
    text-align: center;
    padding: 0 0 0 0;
}
.InfoModal .p-dialog-footer{
    padding: 15px !important;
    text-align: center;
}

.info-footer .button-dark{
    width: 90% !important;
}