

/* Import fonts from Google */  
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap');

html, 
body, 
#root{
  
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Manrope', sans-serif;
  color: #003F56;
  background-color: #F6F6F6;

  --primary-font-family: Manrope;
  --monospace-font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

  /* Colours */
  --color-primary: #003F56;
  --color-primary-variant: #72CCB5;
  --color-secondary: #bbbbbb;
  --color-secondary-variant: #FFFFCB;
  --bg-primary: #E5E5E5;
  --bg-secondary: #F6F6F6;

  /* Debug border */
  /* --debug-border: solid 2px darkred;  */
  --debug-border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2{
  font-size:40px;
  font-family: 'Manrope', sans-serif;
  font-weight: 800; /* Extra bold */
  border: var(--debug-border);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h3{
  font-family: 'Manrope', sans-serif;
  font-size:22px;
  font-weight: 800; /* Extra bold */
  border: var(--debug-border);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h4{
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
}

button{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    border: 2.5px solid var(--color-primary);
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    font-size: 16px;
    background-color: transparent;
}

.button-light{
  color: var(--color-primary);
}
.button-light:hover{
  background-color: var(--bg-primary);
  border-color: var(--color-primary); 
}

.button-dark{
  background-color: var(--color-primary);
  border: none;
  color: var(--color-secondary-variant);
  height: 60px;
  font-family: 'Roboto Mono', monospace;
  font-weight: bolder;
  font-size: 16px;
}

.button-dark:hover{
  background-color:transparent;
  color: var(--color-primary);
  background-color: #809FAB;
  border: solid 2px var(--color-primary); 
}

