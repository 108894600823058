.App {
  /* text-align: center; */
  position: relative;
  height: 100%;
  width:100%;
  display: block;
  /* border: purple solid 2px;  */
}

.App .menu {
  border: var(--debug-border);
  width: 80%;
  max-width: 400px;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu button{
  margin: 10px 0 10px 0; 
}

/* .App .navigation{
  height: 200px;
  width: 400px;
  border-style: solid;
  border-color: purple;
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */







