
.Detect{
    position: relative;
    width: 100%;
    height: 100%;
    /* border-style: solid;
    border-color: green; */
}

.Detect .center-div {
    border: var(--debug-border);
    max-width: 380px;
    height: 200px;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Detect button{
    width: 80%;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
}

.Detect h3{
    position: absolute;
    bottom: 0; 
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    font-weight: 800;
    font-size: 20px;
}

.Detect .loader-div{
    position: absolute;
    bottom: 40px;
    left: 50%;
    /* border: solid green 3px; */
    height: 200px;
    width: 200px;
    transform: translate(-50%, 0);
}



