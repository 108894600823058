@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap);
.App {
  /* text-align: center; */
  position: relative;
  height: 100%;
  width:100%;
  display: block;
  /* border: purple solid 2px;  */
}

.App .menu {
  border: var(--debug-border);
  width: 80%;
  max-width: 400px;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.menu button{
  margin: 10px 0 10px 0; 
}

/* .App .navigation{
  height: 200px;
  width: 400px;
  border-style: solid;
  border-color: purple;
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */










.FindMyTrain {
    border-width: 10px;
    width: 100%;
    height: 100%;
}


  

.Detect{
    position: relative;
    width: 100%;
    height: 100%;
    /* border-style: solid;
    border-color: green; */
}

.Detect .center-div {
    border: var(--debug-border);
    max-width: 380px;
    height: 200px;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.Detect button{
    width: 80%;
    position: absolute;
    top: 50%; 
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.Detect h3{
    position: absolute;
    bottom: 0; 
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 100%;
    font-weight: 800;
    font-size: 20px;
}

.Detect .loader-div{
    position: absolute;
    bottom: 40px;
    left: 50%;
    /* border: solid green 3px; */
    height: 200px;
    width: 200px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}





/* https://codepen.io/sagarsardiwal/pen/eYOGwZa */
  .OrbitLoader{
    width: 200px;
    height: 200px;
    /* border: blue solid 2px; */
  }
  
  .OrbitLoader .central {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: var(--color-primary);
  }

  /** define animation **/
  @-webkit-keyframes spin {
    from {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
              transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
              transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
              transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
              transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  .OrbitLoader .orbit:nth-child(1) {
    height: 80px;
    width: 80px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid var(--color-primary);;
    -webkit-animation: spin 1.2s linear -2s infinite;;
            animation: spin 1.2s linear -2s infinite;
  }

  .OrbitLoader .orbit:nth-child(1) .orbit-planet {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: var(--color-primary);
    border-radius: 50%;
    top: 6px;
    left: 6px;
  }
  
  .OrbitLoader .orbit:nth-child(2) {
    height: 110px;
    width: 110px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    -webkit-animation: spin 2.4s linear -2s infinite;
            animation: spin 2.4s linear -2s infinite;
  }

  .OrbitLoader .orbit:nth-child(2) .orbit-planet {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: var(--color-primary);
    border-radius: 50%;
    top: 10px;
    left: 10px;
  }
  
  .OrbitLoader .orbit:nth-child(3) {
    height: 140px;
    width: 140px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    -webkit-animation: spin 4s linear -2s infinite;
            animation: spin 4s linear -2s infinite;
  }

  .OrbitLoader .orbit:nth-child(3) .orbit-planet {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: var(--color-primary);
    border-radius: 50%;
    top: 14px;
    left: 14px;
  }


.Train{
    width: 100%;
    height: 100%;
    display: block;
    background-color:  none;
   
}

.Train .container-div{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: var(--debug-border);
    width: 100%;
    max-width: 420px;
    margin: auto;
    height: calc(100% - 80px);
    background-color: white;
    box-shadow: 0 -3px 6px rgba(0,0,0,0.12), 0 -3px 6px rgba(0,0,0,0.18);
    position: relative;
    top: 80px;
    z-index: 20;
}
.Train .summary-div{
    border: var(--debug-border);
    height: 130px;
    max-width: 420px;
    margin: auto;
    width: 100%;
    padding:20px 35px 10px 35px;
    border-bottom:  #E5E5E5 solid 2px;
  
}

.Train .summary-div h3{
    padding-top: 5px;
    font-size: 22px; 
    color: #003F56;
    font-weight: 900px;
    font-family: 'Manrope', sans-serif;
}
.Train .summary-div h4{
    padding-top: 6px;
    font-family: 'Roboto Mono', monospace;
    color: #999999;
    font-size: 16px; 
    white-space: nowrap;
    overflow: hidden;
}

.Train .stops-div{
    border: var(--debug-border);
    height: calc(100% - 230px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 35px 0 35px;
    margin: auto;
}

.Train .footer-div{
    position: relative;
    height: 100px;
    border: var(--debug-border);
    border-top: #E5E5E5 solid 2px;
    margin: auto;
    padding: 20px 35px 0 35px;
}

.Train .footer-div button{
    width: 100%;
}


.Train .loading-div{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}



.StopsDrawing {
    /* border: solid red 2px; */
    height: 100%;
    width: 100%
}


/**
* Component for displaying a trains stops. 
**/

.Stops {
    border: var(--debug-border);
    position: relative;
    display: grid;
    grid-gap: 1px;
    grid-auto-rows:  minmax(70px, 70px); /** this is fixed don't change */
    grid-template-columns: 40px calc(100% - 120px) 80px;
    max-width: 340px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    border: var(--debug-border);
    padding: 30px 0 20px 0;
}

.Stops h3{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    color: #003F56;
    font-weight: 800;
    font-size: 16px;
}
.Stops h4{
    padding: 0 0 0 0;
    margin: 3px 0 0 0;
    font-family: 'Roboto Mono', monospace;
    color: #999999;
    font-size: 12px;
}

.Stops .delayed{
    color: darkred;
}

.Stops .left-column {
    grid-column: 1;
    border: var(--debug-border);
}
.Stops .middle-column {
    grid-column: 2;
    padding-left: 10px;
    border: var(--debug-border);
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Stops .right-column {
    grid-column: 3 ;
    text-align: right;
    padding-right:5px;
    border: var(--debug-border);
}






.StopsModal{
    /* 100% = dialog height, 120px = header + footer */
    width: 96vh !important; 
    max-width:480px;  /* Occupy the 90% of the screen width */
    height: 93vh !important;
    margin: 0;
    /* background-color: red; */
    overflow: hidden;
    
}

.StopsModal .p-dialog-content{
    /* border: var(--debug-border);     */
    border-top: #E5E5E5 solid 2px;
    border-bottom:  #E5E5E5 solid 2px;;
}

.StopsModal .p-dialog-header{
    border: var(--debug-border);   
    padding: 20px 20px 3px 30px !important;
}

.StopsModal .stops-title{
    padding: 0 0 10px 0;
    white-space: nowrap;
    overflow: hidden;
    border: var(--debug-border);
}

.StopsModal .stops-title h3{
    line-height: 1.3em;
    font-family: 'Manrope', sans-serif;
    color: #003F56;
    font-size: 20px;
}

.StopsModal .stops-title h4{
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
    border: var(--debug-border);
    padding: 0;
    margin: 0;
}

.StopsModal .Stops{
    position: relative;
    left: -12px;
}

.StopsModal .body{
    /* border: solid purple 2px; */
    width: 100%;
    /* overflow-y: auto; */
    padding: 30px 0 10px 10px;
}

.StopsModal .stops-footer{
    /* border: solid green 8px; */
    padding: 10px 0 0 0;
}

.StopsModal .button-dark{
    padding: 0 !important;
    width: 100% !important;
    height: 60px !important;
}

.StopsModal .p-dialog-footer{
    padding-top:6px !important;
    text-align: center;
}
.StopsModal .stops-footer{
    text-align: center;
}



.TrainList{
    color: black;
    text-align: left;
    width: 96%;
    max-width: 420px;
    margin: auto;
    margin-top: 120px;
    padding-bottom: 100px;
}

.TrainList h2 {
    font-size:19px;
    padding: 0 0 0 0;
    margin: 0 0 5px 0;
}


.TrainList .train-summary{
    background-color: white;
    padding: 10px 20px 0 20px;
    border-radius: 4px;
    padding-bottom: 10px;
    margin: 20px 0 0 0px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.18);
}

/** The origin and destination station */
.TrainList .train-summary  h2{
    padding: 10px 0 0 0;
    font-size: 20px;
    color: #003F56;
    font-weight: 800;
    margin: 0 0 5px 0;
}

.TrainList .train-summary  h3{
    padding: 5px 0 0 0;
    font-size: 16px;
    color: #999999;
    font-family: 'Roboto Mono', monospace;;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.TrainList .train-summary .view-stops{
    color: #003F56;
    font-size: 14px;
    text-decoration: underline;
    -webkit-text-decoration-color:  #999999;
            text-decoration-color:  #999999;
}

.TrainList .train-summary .view-stops:hover{
    cursor: pointer;
}

/* .train-summary  button{
    background-color: #003F56;
    color: #FFFFCB;
    width: 100%;
    font-family: 'Roboto Mono', monospace;;
    font-weight: bold;
    margin: 0 0 5px 0;
} */

/* .train-summary  button:hover{
    background-color:transparent;
    color: #003F56;
} */

.TrainList .train-rid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 7px;
    margin: 0;
    padding: 0;
}
.TrainList .train-rid p{
    padding: 0;
    margin: 0;
}




.NoResult{
    border: var(--debug-border);
    width: 100%;
    max-width: 400px;
    text-align: center;
    display: block;
    position: absolute;
    top: 53%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.NoResult .no-result-icon{
    padding-bottom:28px;
}

.NoResult h3 {
    width: 100%;
    font-weight: 800;
    font-size: 20px;
    padding: 0px 0 0px 0px;
}


.NoResult h4{
    /* border: red 2px solid; */
    font-size: 13px;
    padding: 10px 15px 0px 15px;
    font-family: var(--monospace-font-family);
    color: #6f7275;

}

.NoResult button{
    margin-top: 20px;
    max-width: 80%;
    width: 260px;
    height: 60px;
    font-family: 'Roboto Mono', monospace;
    font-weight: bolder;
    font-size: 14px;
}



.Header{
    /* border: solid purple 2px; */
    display: flex;
    position: fixed;
    height: 80px;
    width: 100%;
    background-color:transparent; 
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 8%;
    z-index: 10;
    border: var(--debug-border);
}

.Header span:hover path{
    stroke: hsl(195.3,100%,16.9%);
}
.Header span:hover{
    background-color: #e0dfdf;
}


.Header svg{
    stroke: hsl(195.3,100%,16.9%);
    fill: hsl(195.3,100%,16.9%);
    stroke-width: .4px;
}



.Header .header-icon{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border: var(--debug-border);

}

.Header .back-div{
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    z-index: 10;
    cursor: pointer;
    border: var(--debug-border);
    height: 40px;
    background-color: var(--bg-secondary);
    padding: 0 10px 0 10px;
    border-radius: 4px;
}

.Header .arrow-div{
    display: inline-flex;
    border: var(--debug-border);
    width: 18px;
}

.Header h4{
    font-family: 'Roboto Mono', monospace;
    font-weight: bolder;
    border: var(--debug-border);
    margin: 0;
    padding: 0 0 0 4px;
}





.InfoModal{
    width: 96vh !important; 
    max-width:480px;  /* Occupy the 90% of the screen width */
    height: 93vh !important;
    margin: 0;
    /* background-color: red; */
    overflow: hidden;
}
.InfoModal .body{
    padding: 30px 0 0 0px;
}

.InfoModal .p-dialog-header{
    border: var(--debug-border);   
    padding: 20px 20px 15px 20px !important;
}

.InfoModal h2{
    color: #003F56 ;
    font-size:36px ;
    font-family: 'Manrope', sans-serif;
    font-weight: 800; /* Extra bold */
}
.InfoModal h3{
    color: #003F56;
    font-family: 'Manrope', sans-serif;
    font-size:22px;
    font-weight: 800; /* Extra bold */
  }

.InfoModal .p-dialog-content{
    /* border: var(--debug-border);     */
    border-top: #E5E5E5 solid 2px;
    border-bottom:  #E5E5E5 solid 2px;;
}


.InfoModal p{
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color:#212121;
}

.info-footer{
    text-align: center;
    padding: 0 0 0 0;
}
.InfoModal .p-dialog-footer{
    padding: 15px !important;
    text-align: center;
}

.info-footer .button-dark{
    width: 90% !important;
}


.ShareThisTrain {
    /* border: solid purple 2px; */
    width: 100%;
    height: 100%;
}



.RippleLoader .signalbox-ripple {
  text-decoration: none;
  color: #fff;
  width: 12px;
  height: 12px;
  background-color: 	rgba(255, 255, 203, 1);
  margin: 0 auto;
  border-radius: 400px;
  -webkit-animation: signalbox-ripple .7s linear infinite;
  animation: signalbox-ripple .7s linear infinite; 
  z-index: 2; 
}

@-webkit-keyframes signalbox-ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 63, 86, 0.8), 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8)}
  100% {
    box-shadow: 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8), 0 0 0 90px rgba(0, 63, 86, 0.8); } }

@keyframes signalbox-ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 63, 86, 0.8), 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8); }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8), 0 0 0 90px rgba(0, 231, 255, 0); } }

.DestinationStation{
    /* border: solid purple 2px; */
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    z-index: 5;
}

.DestinationStation .center-div {
    /* border: solid purple 2px; */
    max-width: 100%;
    width: 400px;
    height: 200px;
    display: block;
    position: absolute;
    text-align: center;
    margin-top: 140px;
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}


.DestinationStation span{
    width: 80%
}

.p-autocomplete-item{
    font-size: 18px !important;
    color: #003F56 !important;
}

.p-autocomplete-input{
    padding-left: 20px !important;
    color:#959595 !important;
    font-size:18px !important;
    width: 100% !important;
    border-radius: 7px !important;
    border-color: #36ADB8 !important;
    border-width: 2px !important;
    height: 55px !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
}

.p-autocomplete-input:hover,
.p-autocomplete-input:active
{
    border-color: #003F56 !important;
}

.DestinationStation h3{
    padding: 15px 0 10px;
    /* border: purple solid 2px; */
    font-size: 18px;
}

.DestinationStation .detecting-text {
    width: 100%;
    font-weight: 800;
    position: absolute;
    bottom: -30px;
    right: 0;
    font-size: 20px;
    padding: 0px 0 0 0px;
}

.DestinationStation .signalbox-ripple{
    margin-top: 85px;
}




.TrainMap{
    border: solid cadetblue 2px;
    height: 100%;
}

.TrainMap .central-div{
    border: var(--border-debug);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TrainMap iframe{
    width: 100%;
    height: 100%
   }

.TrainMap .train-summary{
    margin: auto;
    max-width: 450px;
    width: 95%;
    position: fixed;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    background-color: white;
    border-radius: 4px;
    padding: 20px 30px 20px 30px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.18);
    
}

.TrainMap h2{
    font-size: 28px;
    padding: 0 0 0px 0;
    line-height: 1.1em;
}

.TrainMap h3{
    font-size: 18px;
    padding: 5px 0 5px 0;
}

.TrainMap h4{
    font-size: 14px;
    font-family: 'Roboto Mono', monospace;
    padding: 5px 0 10px 0;
    color: #999999;
}

.TrainMap .train-summary .view-stops{
    color: #003F56;
    font-size: 14px;
    text-decoration: underline;
    -webkit-text-decoration-color:  #999999;
            text-decoration-color:  #999999;
}

.TrainMap .train-summary .view-stops:hover{
    cursor: pointer;
}

.train-marker{
    height: 30px;
    width: 30px;
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.train-marker img{
    width: 100%;
    height: 100%;
}


.EtaSharing {
    width: calc(100% - 30px);
    padding: 20px 30px 20px 30px;
    max-width: 420px;
    position: fixed;
    bottom: 15px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.18);
    border: var(--border-debug);
    opacity: 0.95;
}

.EtaSharing .top-line{
    display: flex;
    justify-content: space-between;
    border: var(--debug-border);
    cursor: pointer;
}

.EtaSharing .chevron{
    position: relative;
    width: 35px;
    height: 35px;
    border: var(--debug-border);
    flex-shrink: 0;
}
.EtaSharing .chevron path{
    stroke: var(--color-primary);
    fill: var(--color-primary)
}

.EtaSharing h2{
    font-size: 23px;
    padding: 0 0 0px 0;
    line-height: 1.1em;
}

.EtaSharing h3{
    font-size: 17px;
    padding: 5px 0 5px 0;
}

.EtaSharing h4{
    font-size: 12px;
    font-family: 'Roboto Mono', monospace;
    padding: 5px 0 10px 0;
    color: #999999;
}

.EtaSharing .expand{
    color: #003F56;
    font-size: 14px;
    text-decoration: underline;
    -webkit-text-decoration-color:  #999999;
            text-decoration-color:  #999999;
    text-align: right;
}

.EtaSharing .view-stops{
    color: #003F56;
    font-size: 12px;
    text-decoration: underline;
    -webkit-text-decoration-color:  #999999;
            text-decoration-color:  #999999;
}

.EtaSharing .view-stops:hover{
    cursor: pointer;
}


/* Import fonts from Google */

html, 
body, 
#root{
  
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Manrope', sans-serif;
  color: #003F56;
  background-color: #F6F6F6;

  --primary-font-family: Manrope;
  --monospace-font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

  /* Colours */
  --color-primary: #003F56;
  --color-primary-variant: #72CCB5;
  --color-secondary: #bbbbbb;
  --color-secondary-variant: #FFFFCB;
  --bg-primary: #E5E5E5;
  --bg-secondary: #F6F6F6;

  /* Debug border */
  /* --debug-border: solid 2px darkred;  */
  --debug-border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2{
  font-size:40px;
  font-family: 'Manrope', sans-serif;
  font-weight: 800; /* Extra bold */
  border: var(--debug-border);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h3{
  font-family: 'Manrope', sans-serif;
  font-size:22px;
  font-weight: 800; /* Extra bold */
  border: var(--debug-border);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h4{
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
}

button{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    border: 2.5px solid var(--color-primary);
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    font-size: 16px;
    background-color: transparent;
}

.button-light{
  color: var(--color-primary);
}
.button-light:hover{
  background-color: var(--bg-primary);
  border-color: var(--color-primary); 
}

.button-dark{
  background-color: var(--color-primary);
  border: none;
  color: var(--color-secondary-variant);
  height: 60px;
  font-family: 'Roboto Mono', monospace;
  font-weight: bolder;
  font-size: 16px;
}

.button-dark:hover{
  background-color:transparent;
  color: var(--color-primary);
  background-color: #809FAB;
  border: solid 2px var(--color-primary); 
}


