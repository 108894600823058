

.RippleLoader .signalbox-ripple {
  text-decoration: none;
  color: #fff;
  width: 12px;
  height: 12px;
  background-color: 	rgba(255, 255, 203, 1);
  margin: 0 auto;
  border-radius: 400px;
  -webkit-animation: signalbox-ripple .7s linear infinite;
  animation: signalbox-ripple .7s linear infinite; 
  z-index: 2; 
}

@-webkit-keyframes signalbox-ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 63, 86, 0.8), 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8)}
  100% {
    box-shadow: 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8), 0 0 0 90px rgba(0, 63, 86, 0.8); } }

@keyframes signalbox-ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 63, 86, 0.8), 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8); }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 63, 86, 0.8), 0 0 0 30px rgba(0, 63, 86, 0.8), 0 0 0 60px rgba(0, 63, 86, 0.8), 0 0 0 90px rgba(0, 231, 255, 0); } }