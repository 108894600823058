

.Train{
    width: 100%;
    height: 100%;
    display: block;
    background-color:  none;
   
}

.Train .container-div{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: var(--debug-border);
    width: 100%;
    max-width: 420px;
    margin: auto;
    height: calc(100% - 80px);
    background-color: white;
    box-shadow: 0 -3px 6px rgba(0,0,0,0.12), 0 -3px 6px rgba(0,0,0,0.18);
    position: relative;
    top: 80px;
    z-index: 20;
}
.Train .summary-div{
    border: var(--debug-border);
    height: 130px;
    max-width: 420px;
    margin: auto;
    width: 100%;
    padding:20px 35px 10px 35px;
    border-bottom:  #E5E5E5 solid 2px;
  
}

.Train .summary-div h3{
    padding-top: 5px;
    font-size: 22px; 
    color: #003F56;
    font-weight: 900px;
    font-family: 'Manrope', sans-serif;
}
.Train .summary-div h4{
    padding-top: 6px;
    font-family: 'Roboto Mono', monospace;
    color: #999999;
    font-size: 16px; 
    white-space: nowrap;
    overflow: hidden;
}

.Train .stops-div{
    border: var(--debug-border);
    height: calc(100% - 230px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 35px 0 35px;
    margin: auto;
}

.Train .footer-div{
    position: relative;
    height: 100px;
    border: var(--debug-border);
    border-top: #E5E5E5 solid 2px;
    margin: auto;
    padding: 20px 35px 0 35px;
}

.Train .footer-div button{
    width: 100%;
}


.Train .loading-div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
