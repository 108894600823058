

.TrainList{
    color: black;
    text-align: left;
    width: 96%;
    max-width: 420px;
    margin: auto;
    margin-top: 120px;
    padding-bottom: 100px;
}

.TrainList h2 {
    font-size:19px;
    padding: 0 0 0 0;
    margin: 0 0 5px 0;
}


.TrainList .train-summary{
    background-color: white;
    padding: 10px 20px 0 20px;
    border-radius: 4px;
    padding-bottom: 10px;
    margin: 20px 0 0 0px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.18);
}

/** The origin and destination station */
.TrainList .train-summary  h2{
    padding: 10px 0 0 0;
    font-size: 20px;
    color: #003F56;
    font-weight: 800;
    margin: 0 0 5px 0;
}

.TrainList .train-summary  h3{
    padding: 5px 0 0 0;
    font-size: 16px;
    color: #999999;
    font-family: 'Roboto Mono', monospace;;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.TrainList .train-summary .view-stops{
    color: #003F56;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-color:  #999999;
}

.TrainList .train-summary .view-stops:hover{
    cursor: pointer;
}

/* .train-summary  button{
    background-color: #003F56;
    color: #FFFFCB;
    width: 100%;
    font-family: 'Roboto Mono', monospace;;
    font-weight: bold;
    margin: 0 0 5px 0;
} */

/* .train-summary  button:hover{
    background-color:transparent;
    color: #003F56;
} */

.TrainList .train-rid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 7px;
    margin: 0;
    padding: 0;
}
.TrainList .train-rid p{
    padding: 0;
    margin: 0;
}
