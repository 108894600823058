
.StopsModal{
    /* 100% = dialog height, 120px = header + footer */
    width: 96vh !important; 
    max-width:480px;  /* Occupy the 90% of the screen width */
    height: 93vh !important;
    margin: 0;
    /* background-color: red; */
    overflow: hidden;
    
}

.StopsModal .p-dialog-content{
    /* border: var(--debug-border);     */
    border-top: #E5E5E5 solid 2px;
    border-bottom:  #E5E5E5 solid 2px;;
}

.StopsModal .p-dialog-header{
    border: var(--debug-border);   
    padding: 20px 20px 3px 30px !important;
}

.StopsModal .stops-title{
    padding: 0 0 10px 0;
    white-space: nowrap;
    overflow: hidden;
    border: var(--debug-border);
}

.StopsModal .stops-title h3{
    line-height: 1.3em;
    font-family: 'Manrope', sans-serif;
    color: #003F56;
    font-size: 20px;
}

.StopsModal .stops-title h4{
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
    border: var(--debug-border);
    padding: 0;
    margin: 0;
}

.StopsModal .Stops{
    position: relative;
    left: -12px;
}

.StopsModal .body{
    /* border: solid purple 2px; */
    width: 100%;
    /* overflow-y: auto; */
    padding: 30px 0 10px 10px;
}

.StopsModal .stops-footer{
    /* border: solid green 8px; */
    padding: 10px 0 0 0;
}

.StopsModal .button-dark{
    padding: 0 !important;
    width: 100% !important;
    height: 60px !important;
}

.StopsModal .p-dialog-footer{
    padding-top:6px !important;
    text-align: center;
}
.StopsModal .stops-footer{
    text-align: center;
}
