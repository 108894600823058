.EtaSharing {
    width: calc(100% - 30px);
    padding: 20px 30px 20px 30px;
    max-width: 420px;
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.18);
    border: var(--border-debug);
    opacity: 0.95;
}

.EtaSharing .top-line{
    display: flex;
    justify-content: space-between;
    border: var(--debug-border);
    cursor: pointer;
}

.EtaSharing .chevron{
    position: relative;
    width: 35px;
    height: 35px;
    border: var(--debug-border);
    flex-shrink: 0;
}
.EtaSharing .chevron path{
    stroke: var(--color-primary);
    fill: var(--color-primary)
}

.EtaSharing h2{
    font-size: 23px;
    padding: 0 0 0px 0;
    line-height: 1.1em;
}

.EtaSharing h3{
    font-size: 17px;
    padding: 5px 0 5px 0;
}

.EtaSharing h4{
    font-size: 12px;
    font-family: 'Roboto Mono', monospace;
    padding: 5px 0 10px 0;
    color: #999999;
}

.EtaSharing .expand{
    color: #003F56;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-color:  #999999;
    text-align: right;
}

.EtaSharing .view-stops{
    color: #003F56;
    font-size: 12px;
    text-decoration: underline;
    text-decoration-color:  #999999;
}

.EtaSharing .view-stops:hover{
    cursor: pointer;
}