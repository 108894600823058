
/* https://codepen.io/sagarsardiwal/pen/eYOGwZa */
  .OrbitLoader{
    width: 200px;
    height: 200px;
    /* border: blue solid 2px; */
  }
  
  .OrbitLoader .central {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: var(--color-primary);
  }

  /** define animation **/
  @keyframes spin {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  .OrbitLoader .orbit:nth-child(1) {
    height: 80px;
    width: 80px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid var(--color-primary);;
    animation: spin 1.2s linear -2s infinite;
  }

  .OrbitLoader .orbit:nth-child(1) .orbit-planet {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: var(--color-primary);
    border-radius: 50%;
    top: 6px;
    left: 6px;
  }
  
  .OrbitLoader .orbit:nth-child(2) {
    height: 110px;
    width: 110px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    animation: spin 2.4s linear -2s infinite;
  }

  .OrbitLoader .orbit:nth-child(2) .orbit-planet {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: var(--color-primary);
    border-radius: 50%;
    top: 10px;
    left: 10px;
  }
  
  .OrbitLoader .orbit:nth-child(3) {
    height: 140px;
    width: 140px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    animation: spin 4s linear -2s infinite;
  }

  .OrbitLoader .orbit:nth-child(3) .orbit-planet {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: var(--color-primary);
    border-radius: 50%;
    top: 14px;
    left: 14px;
  }