
.TrainMap{
    border: solid cadetblue 2px;
    height: 100%;
}

.TrainMap .central-div{
    border: var(--border-debug);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.TrainMap iframe{
    width: 100%;
    height: 100%
   }

.TrainMap .train-summary{
    margin: auto;
    max-width: 450px;
    width: 95%;
    position: fixed;
    bottom: 20px;
    transform: translate(-50%, 0%);
    background-color: white;
    border-radius: 4px;
    padding: 20px 30px 20px 30px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.18);
    
}

.TrainMap h2{
    font-size: 28px;
    padding: 0 0 0px 0;
    line-height: 1.1em;
}

.TrainMap h3{
    font-size: 18px;
    padding: 5px 0 5px 0;
}

.TrainMap h4{
    font-size: 14px;
    font-family: 'Roboto Mono', monospace;
    padding: 5px 0 10px 0;
    color: #999999;
}

.TrainMap .train-summary .view-stops{
    color: #003F56;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-color:  #999999;
}

.TrainMap .train-summary .view-stops:hover{
    cursor: pointer;
}

.train-marker{
    height: 30px;
    width: 30px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.train-marker img{
    width: 100%;
    height: 100%;
}

