
.DestinationStation{
    /* border: solid purple 2px; */
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    z-index: 5;
}

.DestinationStation .center-div {
    /* border: solid purple 2px; */
    max-width: 100%;
    width: 400px;
    height: 200px;
    display: block;
    position: absolute;
    text-align: center;
    margin-top: 140px;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.DestinationStation span{
    width: 80%
}

.p-autocomplete-item{
    font-size: 18px !important;
    color: #003F56 !important;
}

.p-autocomplete-input{
    padding-left: 20px !important;
    color:#959595 !important;
    font-size:18px !important;
    width: 100% !important;
    border-radius: 7px !important;
    border-color: #36ADB8 !important;
    border-width: 2px !important;
    height: 55px !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
}

.p-autocomplete-input:hover,
.p-autocomplete-input:active
{
    border-color: #003F56 !important;
}

.DestinationStation h3{
    padding: 15px 0 10px;
    /* border: purple solid 2px; */
    font-size: 18px;
}

.DestinationStation .detecting-text {
    width: 100%;
    font-weight: 800;
    position: absolute;
    bottom: -30px;
    right: 0;
    font-size: 20px;
    padding: 0px 0 0 0px;
}

.DestinationStation .signalbox-ripple{
    margin-top: 85px;
}


