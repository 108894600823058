/**
* Component for displaying a trains stops. 
**/

.Stops {
    border: var(--debug-border);
    position: relative;
    display: grid;
    grid-gap: 1px;
    grid-auto-rows:  minmax(70px, 70px); /** this is fixed don't change */
    grid-template-columns: 40px calc(100% - 120px) 80px;
    max-width: 340px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    border: var(--debug-border);
    padding: 30px 0 20px 0;
}

.Stops h3{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    color: #003F56;
    font-weight: 800;
    font-size: 16px;
}
.Stops h4{
    padding: 0 0 0 0;
    margin: 3px 0 0 0;
    font-family: 'Roboto Mono', monospace;
    color: #999999;
    font-size: 12px;
}

.Stops .delayed{
    color: darkred;
}

.Stops .left-column {
    grid-column: 1;
    border: var(--debug-border);
}
.Stops .middle-column {
    grid-column: 2;
    padding-left: 10px;
    border: var(--debug-border);
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Stops .right-column {
    grid-column: 3 ;
    text-align: right;
    padding-right:5px;
    border: var(--debug-border);
}




