


.NoResult{
    border: var(--debug-border);
    width: 100%;
    max-width: 400px;
    text-align: center;
    display: block;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.NoResult .no-result-icon{
    padding-bottom:28px;
}

.NoResult h3 {
    width: 100%;
    font-weight: 800;
    font-size: 20px;
    padding: 0px 0 0px 0px;
}


.NoResult h4{
    /* border: red 2px solid; */
    font-size: 13px;
    padding: 10px 15px 0px 15px;
    font-family: var(--monospace-font-family);
    color: #6f7275;

}

.NoResult button{
    margin-top: 20px;
    max-width: 80%;
    width: 260px;
    height: 60px;
    font-family: 'Roboto Mono', monospace;
    font-weight: bolder;
    font-size: 14px;
}
